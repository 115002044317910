import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Loading from "./screen/components/Loading";

const App = React.lazy(() => import('./App'));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Suspense
	fallback={
		<Loading
			status={"load"}
			message={"กำลังโหลด"}
		/>
	}
>
	<App />
</Suspense>
	// <React.StrictMode>
		// <Suspense
		// 	fallback={
		// 		<Loading
		// 			status={"load"}
		// 			message={"กำลังโหลด"}
		// 		/>
		// 	}
		// >
		// 	<App />
		// </Suspense>
	// </React.StrictMode>
);

reportWebVitals();
