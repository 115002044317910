import React from 'react';
import { useMediaQuery } from "@mui/material";
import Success from '../../assets/images/success.png'
import Failure from '../../assets/images/failure.png'
import Load from '../../assets/images/loading.png'

const Loading = ({ status, message }) => {
  const isMobile = useMediaQuery("(max-width: 880px)");

  const styles = {
		container: {
			backgroundColor: "rgba(0,0,0,0.3)",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			height: "100vh", // ระบุความสูงเต็มหน้าจอ
			maxWidth: "100%",
			width: "100%",
			position: "fixed",
			top: 0,
			left: 0,
			zIndex: 9999, // กำหนด zIndex เพื่อให้ Loading อยู่หน้าเสมอ
		},
		borderCard: {
			backgroundColor: "#ffff",
			borderRadius: "15px",
			width: "300px",
			height: "300px",
			padding: "20px",
			textAlign: "center",
			justifyContent: "center",
			alignItems: "center",
			display: "flex",
			flexDirection: 'column'
		},
		fontTitle: {
			fontSize: "24px",
			fontWeight: "bold",
			fontFamily: "Prompt",
			color: "#039BE5",
		},
	};

  return (
		<div style={styles.container}>
			<div style={styles.borderCard}>
				<img
					src={status === "success" && Success || status === "fail" && Failure || Load}
					style={isMobile ? styles.imgLogoM : styles.imgLogo}
					alt='Logo'
				/>
				<p style={styles.fontTitle}>{message ? message : (status === "success" && "บันทึกสำเร็จ" || status === "fail" && "บันทึกข้อมูลไม่สำเร็จ" || status === "load" && "กำลังโหลดข้อมูล")}</p>
			</div>
		</div>
	);
};

export default Loading;
